var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/material';
import { PATH_DASHBOARD } from '../../routes/paths';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../components/settings';
import BookSaleForm from '../../sections/@dashboard/BookSales/BookSaleForm';
// ----------------------------------------------------------------------
export default function BookSalesNew() {
    var themeStretch = useSettingsContext().themeStretch;
    return (_jsxs(_Fragment, { children: [_jsx(Helmet, { children: _jsx("title", { children: " Book Sales : Create a Book Sale " }) }), _jsxs(Container, __assign({ maxWidth: themeStretch ? false : 'xl' }, { children: [_jsx(CustomBreadcrumbs, { heading: "Create a new Book Sale", links: [
                            {
                                name: 'Book Sales',
                                href: PATH_DASHBOARD.books.bookSales.root,
                            },
                            { name: 'New Book Sale' },
                        ] }), _jsx(BookSaleForm, { isView: false })] }))] }));
}
